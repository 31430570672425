import defaultTheme from '../theme'
import * as colors from './colors'
import * as text from './text'

const theme: typeof defaultTheme = {
  ...defaultTheme,
  colors,
  text,
}

export default theme
