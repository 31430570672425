import type { AppProps } from 'next/app'

import GlobalStyles from '../components/GlobalStyles'
import PortalRoot from '../components/PortalRoot'
import ThemeProvider from '../components/ThemeProvider'
import PathHistory from '../components/PathHistory'
import GoogleTagManager from '../components/GoogleTagManager'

const App = ({ Component, pageProps }: AppProps) => (
  <ThemeProvider>
    <PathHistory>
      <PortalRoot>
        <GlobalStyles />
        <Component {...pageProps} />
        <GoogleTagManager />
      </PortalRoot>
    </PathHistory>
  </ThemeProvider>
)

export default App
