import { css, Interpolation, Theme } from '@emotion/react'
import { ButtonHTMLAttributes, ReactNode } from 'react'

export const primaryButtonCss = (theme: Theme) => css`
  padding: 12px ${theme.spacing.x2}px;
  font-size: 14px;
  background: ${theme.colors.buttonPrimaryBackground};
  color: ${theme.colors.buttonPrimaryColor};
  cursor: pointer;
  line-height: 1.6;
  font-weight: bold;
  border: 0;
  display: inline-block;

  transition: background 200ms ease-out;

  :hover {
    background: ${theme.colors.buttonPrimaryHoverBackground};
    color: ${theme.colors.buttonPrimaryHoverColor};
    text-decoration: none;
  }
  :disabled,
  [disabled] {
    background: ${theme.colors.buttonPrimaryDisabledBackground};
    color: ${theme.colors.buttonPrimaryDisabledColor};
    cursor: not-allowed;
  }
`

export const primaryButtonInvertedCss = (theme: Theme) => css`
  border: 0;
  background: ${theme.colors.buttonPrimaryColor};
  color: ${theme.colors.buttonPrimaryInvertedColor};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.6;
  padding: 12px ${theme.spacing.x2}px;
  transition: background 200ms ease-out;

  :hover {
    background: ${theme.colors.buttonPrimaryInvertedHoverBackground};
    text-decoration: none;
  }
  :disabled,
  [disabled] {
    background: ${theme.colors.buttonPrimaryColor};
    color: ${theme.colors.buttonPrimaryInvertedDisabledColor};
    cursor: not-allowed;
  }
`

export const secondaryButtonCss = (theme: Theme) => css`
  ${primaryButtonCss(theme)}
  background: ${theme.colors.buttonSecondaryBackground};
  color: ${theme.colors.buttonSecondaryColor};
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mediumButtonCss = (theme: Theme) => css`
  padding: ${theme.spacing.x2}px;
  font-size: 14px;

  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    font-size: 16px;
  }
`
export const invisibleButtonCss = () => css`
  border: 0;
  padding: 0;
`
export const outlineButtonCss = (theme: Theme) => css`
  ${primaryButtonCss(theme)}
  background: transparent;
  border: 1px solid ${theme.colors.buttonOutlineColor};
  color: ${theme.colors.buttonOutlineColor};

  :hover {
    background: ${theme.colors.buttonOutlineHoverBackground};
    color: ${theme.colors.buttonOutlineHoverColor};
  }
`

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  // Needed for Storybook
  css?: Interpolation<Theme>
}

const Button = ({ children, type, ...others }: Props) => (
  // eslint-disable-next-line react/button-has-type
  <button type={type || 'button'} {...others}>
    {children}
  </button>
)

export default Button
