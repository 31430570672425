import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { makeVar, useReactiveVar } from '../utils/reactiveVar'
import Button, { invisibleButtonCss } from './Button'
import Cross from '../icons/Cross.svg'

export const isModalOpenVar = makeVar(false)

const Section = styled.aside<{ isOpen: boolean }>(
  ({ isOpen, theme }) => css`
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    transform: ${isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 300ms ease;
    transition-delay: ${isOpen ? '0ms' : '300ms'};
    width: 100%;
    z-index: ${theme.zIndex.modal};

    ::before {
      background: rgba(0, 0, 0, 0.5);
      bottom: 0;
      content: '';
      cursor: pointer;
      left: 0;
      right: 0;
      top: 0;
      transition: opacity 300ms ease;
      transition-delay: ${isOpen ? '300ms' : '0ms'};
      opacity: ${isOpen ? 1 : 0};
      position: absolute;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      height: calc(100vh - ${theme.spacing.navigationHeight.desktop}px);
      position: absolute;
      top: 100%;
      transition: transform 0ms ease;
      transition-delay: ${isOpen ? '0ms' : '300ms'};

      ::before {
        transition-delay: 0ms;
      }
    }
  `,
)
const Container = styled.div<{ isOpen: boolean }>(
  ({ isOpen, theme }) => css`
    background: ${theme.colors.neutral0};
    height: 100%;
    position: relative;
    transform: ${isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 300ms ease;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 466px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      background: transparent;
      margin: 0 auto;
      max-width: ${theme.spacing.containerMaxWidth}px;
      position: relative;
      transform: none;
    }
  `,
)
const Content = styled.div<{ isOpen: boolean }>(
  ({ isOpen, theme }) => css`
    padding: ${theme.spacing.x3}px 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      background: ${theme.colors.neutral0};
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      max-height: 500px;
      opacity: ${isOpen ? 1 : 0};
      overflow: auto;
      position: absolute;
      right: 0;
      top: -29px;
      transition: opacity 300ms ease;
      width: 342px;
      overscroll-behavior: none;
    }
  `,
)
const Header = styled.div(
  ({ theme }) => css`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.neutral1};
    display: flex;
    font-size: 16px;
    font-weight: bold;
    padding: 0 ${theme.spacing.x3}px ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      border: 0;
      padding: 0 ${theme.spacing.x3}px ${theme.spacing.x2}px;
    }
  `,
)
const Title = styled.div(
  ({ theme }) => css`
    flex: 1;
    ${theme.text.headings(theme)}
    font-size: 24px;
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.x3}px ${theme.spacing.x3}px 0;
    height: calc(100vh - 112px);
    overflow: auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      height: auto;
      overflow: visible;
      padding: 0 ${theme.spacing.x3}px;
    }

    // TODO: find a solution to remove this styles
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 0 !important;
    }
  `,
)
const Close = styled(Button)(({ theme }) => [
  invisibleButtonCss,
  css`
    cursor: pointer;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
])
const StyledCross = styled(Cross)`
  height: 24px;
  vertical-align: middle;
`

interface Props {
  text: ReactNode
  title?: string | null
}

const QuickHelpModal = ({ text, title }: Props) => {
  const isModalOpen = useReactiveVar(isModalOpenVar)
  return (
    <Section isOpen={isModalOpen} onClick={() => isModalOpenVar(false)}>
      <Container isOpen={isModalOpen}>
        <Content isOpen={isModalOpen} onClick={(e) => e.stopPropagation()}>
          <Header>
            <Title>{title}</Title>
            <Close onClick={() => isModalOpenVar(false)}>
              <StyledCross aria-label="Close" />
            </Close>
          </Header>
          <Text>{text}</Text>
        </Content>
      </Container>
    </Section>
  )
}

export default QuickHelpModal
