import { Inter, Raleway } from 'next/font/google'

const inter = Inter({
  subsets: ['latin'],
  weight: 'variable',
  variable: '--font-inter',
})

const raleway = Raleway({
  subsets: ['latin'],
  weight: 'variable',
  variable: '--font-raleway',
})

const fonts = { inter, raleway }

export default fonts
