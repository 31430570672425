import Script from 'next/script'

import { createGoogleTagManagerScript } from '../utils/createGoogleTagManagerScript'

const SCRIPT_GTM = 'script-gtm'

export const GoogleTagManagerScript = () => {
  const gtmCode = process.env.NEXT_PUBLIC_GTM_CODE

  if (gtmCode) {
    return (
      <Script
        id={SCRIPT_GTM}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: createGoogleTagManagerScript(gtmCode),
        }}
      />
    )
  }

  return null
}

export const GoogleTagManagerNoScript = () => {
  const gtmCode = process.env.NEXT_PUBLIC_GTM_CODE

  if (gtmCode) {
    return (
      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmCode}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    )
  }

  return null
}

const GoogleTagManager = () => (
  <>
    <GoogleTagManagerScript />
    <GoogleTagManagerNoScript />
  </>
)

export default GoogleTagManager
