export const x0 = 4
export const x1 = 8
export const x2 = 16
export const x3 = 24
export const x4 = 32
export const x5 = 40
export const x6 = 48
export const x7 = 56
export const x8 = 64
export const x9 = 72
export const x10 = 80
export const x11 = 88
export const x12 = 96
export const x13 = 104
export const x14 = 112
export const x15 = 120
export const x16 = 128
export const x17 = 136
export const x18 = 144
export const x19 = 152
export const x20 = 160

export const gridMaxWidth = 1128
export const containerMaxWidth = 1280
export const containerWidth = 744

// This hardcoded height is not ideal, bust it mostly affects the menu/modals backdrops
// It will still work if navigation height gets bigger.
export const navigationHeight = {
  desktop: 161,
  mobile: 66,
}
