/* eslint-disable import/export */
import * as baseColors from '../colors'

export * from '../colors'

// Only exists on CED
export const primaryGreen = '#86bc35'
export const primaryGreenDark = '#45890b'
export const primaryGreenLight = '#c2dd9a'
export const primaryGreenExtraLight = '#e0eecc'

export const buttonPrimaryBackground = primaryGreen
export const buttonPrimaryColor = baseColors.neutral0
export const buttonPrimaryHoverBackground = primaryGreenDark
export const buttonPrimaryHoverColor = baseColors.neutral0
export const buttonPrimaryDisabledBackground = primaryGreenExtraLight
export const buttonPrimaryDisabledColor = baseColors.neutral1
export const buttonSecondaryBackground = baseColors.neutral1
export const buttonSecondaryColor = baseColors.primaryPurpleDark
export const buttonOutlineColor = primaryGreen
export const buttonOutlineHoverBackground = primaryGreen
export const buttonOutlineHoverColor = baseColors.neutral0
