// Defaults file, for site-specific theme, go into a site folder

/* eslint-disable @typescript-eslint/no-inferrable-types */
export const primaryPurple: string = '#56238e'
export const primaryPurpleDark: string = '#1c054f'
export const primaryPurpleLight: string = '#ac8cca'
export const primaryPurpleExtraLight: string = '#d4c8e3'
export const neutral0: string = '#fff'
export const neutral1: string = '#ececec'
export const neutral2: string = '#ccc'
export const neutral3: string = '#666'
export const neutral4: string = '#333'

export const error: string = 'red'

export const green: string = '#86bc35'
export const blue: string = '#3899dd'

export const buttonPrimaryBackground: string = primaryPurple
export const buttonPrimaryColor: string = neutral0
export const buttonPrimaryHoverBackground: string = primaryPurpleDark
export const buttonPrimaryHoverColor: string = neutral0
export const buttonPrimaryDisabledBackground: string = primaryPurpleExtraLight
export const buttonPrimaryDisabledColor: string = neutral1
export const buttonPrimaryInvertedColor: string = primaryPurpleDark
export const buttonPrimaryInvertedDisabledColor: string = neutral2
export const buttonPrimaryInvertedHoverBackground: string = neutral1
export const buttonSecondaryBackground: string = neutral1
export const buttonSecondaryColor: string = primaryPurpleDark
export const buttonOutlineColor: string = primaryPurple
export const buttonOutlineHoverBackground: string = primaryPurple
export const buttonOutlineHoverColor: string = neutral0
