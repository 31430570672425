import { css, Theme } from '@emotion/react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const headings = (theme: Theme) =>
  css`
    color: ${theme.colors.primaryPurpleDark};
    letter-spacing: -0.01em;
    line-height: 1.2;
    margin: 0;
    font-family: ${theme.fonts.raleway.style.fontFamily}, sans-serif;
    font-weight: bold;
  `

export const heading1 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 36px;

  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    font-size: 40px;
  }
`
export const heading2 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 30px;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 34px;
  }
`
export const heading3 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 24px;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 28px;
  }
`
export const heading4 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 20px;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 24px;
  }
`
export const heading5 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 16px;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 20px;
  }
`

export const titleLine = (theme: Theme) => css`
  border-left: 5px solid ${theme.colors.primaryPurple};
`
