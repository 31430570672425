import { css, Theme } from '@emotion/react'

/* eslint-disable import/export */
export * from '../text'

export const titleLine = (theme: Theme) => css`
  border-left: 5px solid ${theme.colors.primaryPurple};
  border-left: 5px solid;
  border-image: linear-gradient(
      to bottom,
      ${theme.colors.primaryPurple} 0%,
      ${theme.colors.primaryPurple} 33.33%,
      ${theme.colors.blue} 33.33%,
      ${theme.colors.blue} 66.66%,
      ${theme.colors.green} 66.66%,
      ${theme.colors.green} 100%
    )
    1;
`
