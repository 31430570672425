import { useRouter } from 'next/router'
import { MouseEvent, ReactNode, useCallback, useEffect } from 'react'

import { makeVar, useReactiveVar } from '../utils/reactiveVar'

const pathHistoryVar = makeVar<string[]>([])

export const usePreviousPath = () => {
  const pathHistory = useReactiveVar(pathHistoryVar)
  const currentPathIndex = pathHistory.length - 1
  const previousPathIndex = currentPathIndex - 1

  return pathHistory[previousPathIndex] as string | undefined
}
export const useGoBack = (fallback?: string) => {
  const { back, push } = useRouter()
  const previousPath = usePreviousPath()

  return useCallback(
    () => (e?: MouseEvent) => {
      if (e) {
        e.preventDefault()
      }

      if (previousPath) {
        pathHistoryVar((pathHistory) =>
          pathHistory.slice(0, pathHistory.length - 2),
        )
        // Only use back when we have known history, otherwise we might send the
        // user back to Google or the new tab page.
        back()
      } else {
        push(fallback || '/')
      }
    },
    [previousPath, back, push, fallback],
  )
}

interface Props {
  children: ReactNode
}

const PathHistory = ({ children }: Props) => {
  const { asPath } = useRouter()
  useEffect(() => {
    pathHistoryVar((pathHistory) => [...pathHistory, asPath])
  }, [asPath])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default PathHistory
